<template>
  <div>
    <!-- Compose button -->
    <div class="compose-container" @click.prevent="$showMailCompose()">
      <i class="fas fa-pencil-alt"></i>
      <a class="btn btn-compose">Compose</a>
    </div>

    <!-- ComposeMail component -->
    <ComposeMail
      v-if="isComposeOpen"
      :key="composeMailKey"
      @close="closeComposeModal"
      @send="handleSendMessage"
    />
  </div>
</template>

<script>
import ComposeMail from "../../../views/common/ComposeMail.vue";

export default {
  components: {
    ComposeMail,
  },
  data() {
    return {
      isComposeOpen: false, // Controls the visibility of the ComposeMail modal
      composeMailKey: 0, // Unique key to force re-render
    };
  },
  methods: {
    // Open the compose modal
    openComposeModal() {
      this.composeMailKey++; // Increment key to force re-render
      this.isComposeOpen = true;
    },

    // Close the compose modal
    closeComposeModal() {
      this.isComposeOpen = false;
      console.log("Modal closed. isComposeOpen:", this.isComposeOpen);
    },

    // Handle sending a message
    handleSendMessage(messageData) {
      console.log("Message sent:", messageData);
      this.isComposeOpen = false; // Close the modal after sending
    },
  },
};
</script>

<style scoped>
.compose-container {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px 15px;
  width: fit-content;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.compose-container i {
  font-size: 18px;
  margin-right: 8px;
  color: #007bff;
}

.compose-container .btn-compose {
  text-decoration: none;
  font-size: 16px;
  color: #007bff;
}

.compose-container:hover {
  background-color: #e9ecef;
}

.compose-container:hover i,
.compose-container:hover .btn-compose {
  color: #0056b3;
}
</style>
