<template>
  <aside class="sm-side">
    <div class="user-head">
      <img v-if="profile?.photo" :src="profile.photo" alt="Profile Picture" />
      <div class="user-name">
        <h5>{{ profile?.name }}</h5>
        <span class="email-address">{{ profile?.emailAddress }}</span>
      </div>
    </div>

    <div class="compose-wrapper">
      <app-compose></app-compose>
    </div>
    <ul class="inbox-nav">
      <li :class="{ active: activeView == 'app-inbox' }">
        <a href="#" @click.prevent="navigate('app-inbox', 'Inbox')">
          <i class="fa fa-inbox"></i>Inbox
          <span class="label label-danger pull-right">{{
            unreadMessages.length
          }}</span>
        </a>
      </li>

      <li :class="{ active: activeView == 'app-sent' }">
        <a href="#" @click.prevent="navigate('app-sent', 'Sent')">
          <i class="fa fa-envelope-o"></i>Sent
          <span class="label label-default pull-right">{{
            sentMessages.length
          }}</span>
        </a>
      </li>

      <li :class="{ active: activeView == 'app-important' }">
        <a href="#" @click.prevent="navigate('app-important', 'Important')">
          <i class="fa fa-bookmark-o"></i>Important
          <span class="label label-warning pull-right">{{
            importantMessages.length
          }}</span>
        </a>
      </li>

      <li :class="{ active: activeView == 'app-trash' }">
        <a href="#" @click.prevent="navigate('app-trash', 'Trash')">
          <i class="fa fa-trash-o"></i>Trash
          <span class="label label-default pull-right">{{
            trashedMessages.length
          }}</span>
        </a>
      </li>
    </ul>
    <!-- <ul class="inbox-nav" v-if="labels && labels.length > 0">
      <li
        v-for="folder in labels"
        :key="folder.id"
        :class="{ active: activeView === folder.id }"
      >
        <a href="#" @click.prevent="navigate(folder.id, folder.displayName)">
          <i class="fa fa-folder"></i>
          {{ folder.displayName }}
          <span
            class="label label-default pull-right"
            v-if="folder.unreadItemCount > 0"
          >
            {{ folder.unreadItemCount }}
          </span>
        </a>
      </li>
    </ul> -->
  </aside>
</template>

<script>
import { bus } from "../../../main";
import Compose from "./compose.vue";

import { fetchLabels, fetchProfile } from "./data/messages";

export default {
  props: {
    messages: {
      type: Array,
      required: true,
    },
    mailConfiguration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeView: "app-inbox",
      profile: {},
      labels: [],
    };
  },
  created() {
    this.getUserProfile();
    this.getLabels();
    bus.$on("changeView", (data) => {
      this.activeView = data.tag;
    });
  },
  activated() {
    this.getUserProfile();
    this.getLabels();
  },
  methods: {
    navigate(newView, title) {
      bus.$emit("changeView", {
        tag: newView,
        title: title,
      });
    },
    async getUserProfile() {
      try {
        let data = await fetchProfile(
          this.mailConfiguration.service,
          this.mailConfiguration.user._id
        );
        console.log("Profile Data", data);
        this.profile = data.profile;
      } catch (error) {
        console.error("Failed to fetch profile:", error);
      }
    },
    async getLabels() {
      try {
        let data = await fetchLabels(
          this.mailConfiguration.service,
          this.mailConfiguration.user._id
        );
        this.labels = data.labels.map((folder) => ({
          id: folder.id,
          displayName: folder.displayName,
          unreadItemCount: folder.unreadItemCount || 0,
        }));
      } catch (error) {
        this.labels = [];
      }
    },
  },
  computed: {
    unreadMessages() {
      return this.messages.filter(function (message) {
        return (
          message.type == "incoming" && !message.isRead && !message.isDeleted
        );
      });
    },
    sentMessages() {
      return this.messages.filter(function (message) {
        return message.type == "outgoing" && !message.isDeleted;
      });
    },
    importantMessages() {
      return this.messages.filter(function (message) {
        return (
          message.type == "incoming" &&
          message.isImportant === true &&
          !message.isDeleted
        );
      });
    },
    trashedMessages() {
      return this.messages.filter(function (message) {
        return message.isDeleted === true;
      });
    },
  },
  components: {
    appCompose: Compose,
  },
};
</script>

<style>
/* General Styles */
aside .sm-side {
  width: 180px;
  background-color: #ffffff;
  border-right: 1px solid #e0e0e0;
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* User Head Section */
.user-head {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f0f4f8;
  color: #333;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.user-head img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  border: 2px solid #007bff;
}

.user-name h5 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #007bff;
}

.user-name .email-address {
  font-size: 14px;
  color: #6c757d;
}

/* Compose Button Wrapper */
.compose-wrapper {
  padding: 10px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e0e0e0;
  text-align: left;
}

.compose-wrapper button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.compose-wrapper button:hover {
  background-color: #0056b3;
}

/* Inbox Navigation */
.inbox-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.inbox-nav li {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 5px;
  padding: 4px 0;
}

.inbox-nav li a {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  color: #333;
  text-decoration: none;
  font-size: 12px;
  transition: background-color 0.3s, color 0.3s;
  font-weight: 500;
}

.inbox-nav li a:hover,
.inbox-nav li.active a {
  background-color: #007bff;
  color: #fff;
}

.inbox-nav li a i {
  margin-right: 8px;
  font-size: 12px;
  color: #6c757d;
}

.inbox-nav li a:hover i,
.inbox-nav li.active a i {
  color: #fff;
}

.inbox-nav li a .label {
  margin-left: auto;
  font-size: 12px;
  border-radius: 12px;
  padding: 2px 6px;
}

.label-danger {
  background-color: #dc3545;
  color: #fff;
}

.label-default {
  background-color: #6c757d;
  color: #fff;
}

.label-warning {
  background-color: #ffc107;
  color: #fff;
}

/* Responsive Design */
@media (max-width: 768px) {
  aside.sm-side {
    width: 100%;
    height: auto;
  }

  .user-head {
    flex-direction: column;
    text-align: center;
  }

  .user-head img {
    margin-bottom: 10px;
  }

  .inbox-nav li a {
    font-size: 14px;
  }
}
</style>
