<template>
    <div>
    <table v-if="messages?.length > 0" class="table table-inbox table-hover">
        <tbody>
            <tr v-for="(message, index) in messages" @click="openMessage(message)" :key="message.id || index"  :class="{ unread: typeof message.isRead !== 'undefined' && !message.isRead }">
                <td><input type="checkbox"></td>
                <td>
                    <a href="#" v-if="typeof message.isImportant !== 'undefined'" @click.prevent.stop="message.isImportant = !message.isImportant">
                        <i :class="['fa', 'fa-star', { important: message.isImportant }]"></i>
                    </a>
                </td>
                <td>{{ message.from.name }}</td>
                <td>{{ message.subject }}</td>
                <td><i v-if="message?.attachments?.length > 0" class="fa fa-paperclip"></i></td>
                <td class="text-right">{{ getDateValues(message.date) }}</td>
            </tr>
        </tbody>
    </table>

    <p v-else>No messages here yet.</p>
    </div>
</template>

<script>
    import { bus } from "../../../main";
    import moment from 'moment';

    export default {
        props: {
            messages: {
                type: Array,
                required: true
            },
        },
        methods: {
            openMessage(message) {
                bus.$emit('changeView', {
                    tag: 'app-view-message',
                    title: message.subject,
                    data: {
                        message: message
                    }
                });
            }
        },
        computed:{
            getDateValues()
            {
                return (date)=>{
                return moment(date).fromNow();
                }
            }
        },
        mounted(){
            console.log("Received messages:", this.messages);
        }
    }
</script>


<style scoped>
/* Table Styling */
.table-inbox {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table-inbox th, .table-inbox td {
    padding: 8px 10px;
    text-align: left;
    vertical-align: middle;
}

.table-inbox tbody tr {
    transition: background-color 0.3s;
    cursor: pointer;
}

.table-inbox tbody tr:hover {
    background-color: #f9f9f9;
}

.table-inbox tbody tr.unread {
    font-weight: 600;
    background-color: #f3f8fc;
}

.table-inbox tbody td:first-child {
    width: 3%;
    text-align: center;
}

.table-inbox tbody td:nth-child(2) {
    width: 3%;
    text-align: center;
}

.table-inbox tbody td:nth-child(3) {
    padding-left: 10px; /* Add minimal padding to the sender column */
}

.table-inbox tbody td:nth-child(5) {
    width: 3%;
    text-align: center;
}

.table-inbox tbody td.text-right {
    width: 15%;
    text-align: right;
    font-size: 0.9em;
    color: #888;
}

/* Star Icon Styling */
.fa-star {
    color: #ccc;
    transition: color 0.3s;
    font-size: 1em;
}

.fa-star.important {
    color: #ffcc00;
}

.fa-paperclip {
    font-size: 1 em;
    color: #555;
}

/* No Messages Text */
p {
    text-align: center;
    font-size: 1.1em;
    color: #666;
    margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .table-inbox th, .table-inbox td {
        padding: 8px 6px;
        font-size: 0.9em;
    }

    .table-inbox tbody td.text-right {
        font-size: 0.8em;
    }

    p {
        font-size: 1em;
    }
}
</style>
