<template>
  <div class="container1">
    <!-- Authentication Prompt -->
    <template
      v-if="
        Object.keys(companyMailConfig).length &&
        !Object.keys(loginUserMailConfig).length &&
        showAuthenticateOption
      "
    >
      <h2>Please Authenticate</h2>
      <p>
        You need to authenticate with your Gmail, Outlook, or G Suite account to
        access your emails.
      </p>
      <div class="auth-actions">
        <button
          @click="authenticate('gmail')"
          v-if="companyMailConfig.service === 'gmail'"
          class="auth-btn gmail-btn"
        >
          Authenticate with Gmail
        </button>
        <button
          @click="authenticate('outlook')"
          v-else-if="companyMailConfig.service === 'outlook'"
          class="auth-btn outlook-btn"
        >
          Authenticate with Outlook
        </button>
        <button
          @click="authenticate('gSuite')"
          v-else-if="companyMailConfig.service === 'gSuite'"
          class="auth-btn gsuite-btn"
        >
          Authenticate with G Suite
        </button>
      </div>
    </template>
    <template
      v-else-if="
        !Object.keys(companyMailConfig)?.length &&
        !Object.keys(loginUserMailConfig)?.length
      "
    >
      <h2>Access Denied</h2>
      <p>Please Activate your Email Client then you get the access</p>
  </template>
    <template v-else-if="Object.keys(loginUserMailConfig).length && showTextArea">
      <template
      v-if="companyMailConfig.isInboxVisible"
    >
    <div class="mail-head">
      <table style="width:100%">
        <tr>
          <th style="width:80%;">&nbsp;&nbsp;&nbsp;{{ companyMailConfig?.service?.toUpperCase() }} <small> ( {{ companyMailConfig?.connectionType?.toUpperCase() }}) </small> </th>
          <td style="width:15%; text-align:center;">
            <label >Status</label> &nbsp;&nbsp;&nbsp;
            <el-switch
            v-model="companyMailConfig.isActive"
            @change="updateStatus"
            active-color="#13ce66"
            inactive-color="#ff4949"
          ></el-switch>
          </td>
          <td style="width:5%;text-align:center;">
            <el-button type="danger"  size="mini" icon="el-icon-delete" circle @click="removeIntegration(loginUserMailConfig._id)"></el-button>
          </td>
        </tr>
      </table>
    </div>
      <div class="mail-box">
        <app-sidebar
          :messages="messages"
          :mailConfiguration="{
            ...companyMailConfig,
            user: loginUserMailConfig,
          }"
        ></app-sidebar>
          
        <app-content
          :messages="messages"
          :mailConfiguration="{
            ...companyMailConfig,
            user: loginUserMailConfig,
          }"
        ></app-content>
      </div>
    </template>
    <template v-else>
      <el-row>
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          class="mt-1 mb-5 pt-1 pb-5 pl-1 pr-1"
        >
          <div style="padding: 0 0.55rem">
            <table id="customers">
              <tr>
                <th>Service</th>
                <td>{{ companyMailConfig?.service?.toUpperCase() }}</td>
              </tr>
              <tr v-if="companyMailConfig.connectionType === 'oauth'">
                <th>Connection Type</th>
                <td>{{ companyMailConfig?.connectionType?.toUpperCase() }}</td>
              </tr>
              <tr v-if="companyMailConfig.connectionType === 'oauth'">
                <th>Domain</th>
                <td>{{ companyMailConfig.domain || "N/A" }}</td>
              </tr>
              <tr v-if="companyMailConfig.connectionType === 'imap'">
                <th>Host</th>
                <td>{{ companyMailConfig.imapConfig?.host || "N/A" }}</td>
              </tr>
              <tr v-if="companyMailConfig.connectionType === 'imap'">
                <th>Username</th>
                <td>{{ companyMailConfig.imapConfig?.username || "N/A" }}</td>
              </tr>
              <tr v-if="companyMailConfig.connectionType === 'imap'">
                <th>Port</th>
                <td>{{ companyMailConfig.imapConfig?.port || "N/A" }}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>
                  <el-switch
                    v-model="companyMailConfig.isActive"
                    @change="updateStatus"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                  ></el-switch>
                </td>
              </tr>
              <tr>
                <th>Actions</th>
                <td>
                  <el-button
                    type="danger"
                    size="mini"
                    @click="removeIntegration(loginUserMailConfig._id)"
                  >
                    Remove
                  </el-button>
                </td>
              </tr>
            </table>
          </div>
        </el-col>
        </el-row>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import emailSidebar from "./emailSidebar.vue";
import Content from "./content.vue";
import randomMessages from "./data/random-messages";
import { bus } from "../../../main";
import { postAPICall } from "@/helpers/httpHelper"; // Use your HTTP helper
import Config from "../../../config/app";
import axios from "axios";

export default {
  data() {
    return {
      messages: [],
      accounts: [],
      showEmails: false,
      isAuthenticated: false,
      companyMailConfig: {},
      loginUserMailConfig: {},
      queryData: null,
      showTextArea: false,
      showAuthenticateOption: true,
    };
  },
  computed: {
    ...mapGetters("auth", ["getActiveWorkspace", "getAuthenticatedUser"]),
  },
  watch: {
    async queryData(newValue) {
      if (newValue) {
        await this.getTokensUsingAuthorizationCode(newValue);
      }
    },
  },
  created() {
    bus.$on("refreshMessages", () => {
      let randomIndex = Math.floor(Math.random() * randomMessages.length);
      let temp = [randomMessages[randomIndex]];
      this.messages = temp.concat(this.messages.slice(0));
    });

    bus.$on("sentMessage", (data) => {
      let temp = [data.message];
      this.messages = temp.concat(this.messages.slice(0));
    });
    this.interval = setInterval(this.checkLocalStorage, 2000);
  },
  async mounted() {
    // this.getAccountConnectionList();
    await Promise.all([this.getAccounts(), this.getConfigurations()]);
  },
  methods: {
    async checkLocalStorage() {
      let storageInfo = await localStorage.getItem("extractCode");
      this.queryData = await JSON.parse(storageInfo);
      await localStorage.removeItem("extractCode");
    },
    async getAccountConnectionList() {
      try {
        this.loading = true;
        this.loadingText = "Loading...";
        let response = await postAPICall(
          "GET",
          "/user_integrations/list?app_type=MAIL"
        );
        // if (response && response.success) {
        if (
          response &&
          response.success &&
          response?.data?.data[0].clientSecret
        ) {
          const decrypted = this.$CryptoJS.AES.decrypt(
            response.data[0].clientSecret,
            "MAIL"
          );
          const info = JSON.parse(decrypted.toString(this.$CryptoJS.enc.Utf8));
          this.accounts = [{ ...info, ...response.data }];
          this.showEmails = this.accounts.length;
        } else {
          this.showEmails = false;
        }
      } catch (err) {
        this.showEmails = false;
      }
    },
    async authenticate(service) {
      let redirectUrl = "";
      const callbackResponse = await axios.get(
        `${Config.KF_EMAIL}/auth/${service}/${this.getActiveWorkspace.company_id}/${this.companyMailConfig._id}/${this.getAuthenticatedUser._id}`
      );
      if (
        callbackResponse &&
        callbackResponse.data &&
        callbackResponse.data.success
      ) {
        redirectUrl = callbackResponse.data.callbackUrl;
        // const redirectUri = Config.EMAIL_REDIRECT_URI;
        // if (redirectUri) {
        //   const url = new URL(redirectUrl);
        //   url.searchParams.set("redirect_uri", redirectUri);
        //   redirectUrl = url.toString();
        // }
        window.open(redirectUrl, "_blank", "width=800,height=600");
      } else {
        redirectUrl = "";
        // return;
      }
    },
    async getTokensUsingAuthorizationCode(query) {
      try {
        if (query.state) {
          //query["redirect_uri"] = Config.EMAIL_REDIRECT_URI;
          this.loading = true;
          let tokens = await axios.post(
            `${Config.KF_EMAIL}/${this.companyMailConfig.service}/callback`,
            {
              query,
            }
          );
          console.log("tokens", tokens);
          await Promise.all([this.getAccounts(), this.getConfigurations()]);
          this.$notify.success({
            title: "Success",
            message: "Connected Successfully",
          });
          this.loading = false;
        }
      } catch (err) {
        console.log("error", err);
      }
    },
    async getAccounts() {
      this.loading = true;
      this.loadingText = "Loading configurations ...";
      try {
        let response = await postAPICall("GET", "/user_integrations/list/MAIL");
        if (response && response.success) {
          if (this.$route.params.companyConfigId === response.data._id) {
            const decrypted = this.$CryptoJS.AES.decrypt(
              response.data.clientSecret,
              "MAIL"
            );
            const info = decrypted.toString(this.$CryptoJS.enc.Utf8);
            this.companyMailConfig = JSON.parse(info);

            //   const format = validateAndFormatPhoneNumber(this.companyMailConfig.destination);

            //   if(format.isValid)
            // {
            this.companyMailConfig = {
              ...this.companyMailConfig,
              ...response.data,
            };
            // }
            // else{
            //   // this.$message.error(errorMessage);
            //   console.log(format.errorMessage)
            // }
          } else {
            this.$message.error(
              "Configurations data not matched with your Owner!"
            );
            this.loading = false;
          }
        }
      } catch (error) {
        console.error(error);

        this.$message.error("Invalid Configuration!");
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async getConfigurations() {
      this.loading = true;
      this.loadingText = "Loading User configurations ...";
      try {
        let response = await postAPICall("GET", "/user_integrations/user/MAIL");
        if (response && response.success && response.data.length) {
          this.loginUserMailConfig =
            response.data && response.data.length ? response.data[0] : {};
          const decrypted = this.$CryptoJS.AES.decrypt(
            this.loginUserMailConfig.clientSecret,
            "MAIL"
          );
          const info = decrypted.toString(this.$CryptoJS.enc.Utf8);
          this.loginUserMailConfig = {
            ...this.loginUserMailConfig,
            ...JSON.parse(info),
          };
          this.showTextArea=true
        }
      } catch (error) {
        //this.$message.error("Invalid Configuration!");
        this.loading = false;
        this.showTextArea=false;
      } finally {
        this.loading = false;
      }
    },
    async updateStatus() {
      this.loading = true;
      this.loadingText = "Updating ...";
      try {
        let response = await postAPICall(
          "GET",
          `/user_integrations/user/${this.companyMailConfig._id}/${this.loginUserMailConfig._id}/${this.loginUserMailConfig.isActive}`
        );
        if (response && response.success) {
          this.loading = true;
          this.loadingText = "Loading...";
          this.$message.success("Your Mail Configuration updated!");
          setTimeout(async () => {
            this.loading = false;
            this.loadingText = "Please wait.....";
            await Promise.all([this.getAccounts(), this.getConfigurations()]);
          }, 1500);
        }
      } catch (error) {
        this.$message.error("Failed to update the configuration!");
        await Promise.all([this.getAccounts(), this.getConfigurations()]);
      } finally {
        this.loading = false;
      }
    },
    async removeIntegration(id) {
      this.$confirm("Do you Want to remove this Configuration?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteConfigurations(id);
      });
    },
    async deleteConfigurations(id) {
      this.loading = true;
      const response = await postAPICall(
        "GET",
        `/user_integrations/userintegrations/${id}`
      );
      if (response && response.success) {
        this.$message.success("Integration removed successfully!");
        await Promise.all([this.getAccounts(), this.getConfigurations()]);
        this.showTextArea = false;
        this.loginUserMailConfig = {};
        this.showAuthenticateOption = true;
      } else {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: "Failed to remove integration: ",
        });
      }
    },
  },
  components: {
    appSidebar: emailSidebar,
    appContent: Content,
  },
};
</script>

<style scoped>

.margin-right{
  margin-right:1em;
}
.mail-head{
  width: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
}
.float-right{
  float: right;
}
.mail-box {
  display: flex; /* Use flexbox for consistent layout */
  width: 100%;
  height: 90vh; /* Full height for better alignment */
  border: 1px solid #ccc; /* Add a subtle border to unify the sections */
  /* border-radius: 8px; /* Rounded corners for the whole layout */
  overflow: hidden; /* Ensure content doesn't spill out */
  background-color: #f8f9fa; /* Light background for the entire mail box */
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.mail-box aside,
.mail-box .sm-side {
  width: 22%;
  flex: 0 0 20%; /* Sidebar takes up 15% of the space */
  background: #e5e8ef; /* Matching background color */
  border-right: 1px solid #ccc; /* Add a divider between sidebar and content */
  padding: 10px;
}

.mail-box .lg-side {
  flex: 1; /* Content area takes up the rest of the space */
  background: #fff; /* White background for content */
  padding: 20px;
}

.mail-box aside,
.mail-box .lg-side {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.mail-box aside .header,
.mail-box .lg-side .header {
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  border-bottom: 1px solid #ddd; /* Add a consistent header look */
}

.mail-box aside ul,
.mail-box .lg-side .content {
  flex: 1; /* Take up remaining space */
  overflow-y: auto; /* Add scroll if content exceeds height */
}

.mail-box aside ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mail-box aside ul li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mail-box aside ul li:hover {
  background-color: #d4dae0; /* Subtle hover effect */
}

.mail-box .lg-side .content {
  padding: 20px;
  background-color: #fff;
  border-radius: 0 8px 8px 0; /* Match the main border radius */
}

.auth-prompt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  height: auto;
}

.auth-prompt h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.auth-prompt p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.auth-actions {
  display: flex;
  gap: 15px;
}

.auth-btn {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.gmail-btn {
  background-color: #d93025;
}

.outlook-btn {
  background-color: #0078d4;
}

.gsuite-btn {
  background-color: #34a853;
}

/* Add hover effect */
.auth-btn:hover {
  opacity: 0.9;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}
/*
background-color: #04AA6D;
color: white;
*/
#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}

/* Responsive Design */
@media (max-width: 768px) {
  .mail-box {
    flex-direction: column; /* Stack sidebar and content vertically */
  }

  .mail-box aside {
    flex: 0 0 auto; /* Adjust sidebar height */
    border-right: none; /* Remove border for vertical layout */
    border-bottom: 1px solid #ccc; /* Add separator */
  }
}
</style>
