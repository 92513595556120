var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container1"},[(
      Object.keys(_vm.companyMailConfig).length &&
      !Object.keys(_vm.loginUserMailConfig).length &&
      _vm.showAuthenticateOption
    )?[_c('h2',[_vm._v("Please Authenticate")]),_c('p',[_vm._v(" You need to authenticate with your Gmail, Outlook, or G Suite account to access your emails. ")]),_c('div',{staticClass:"auth-actions"},[(_vm.companyMailConfig.service === 'gmail')?_c('button',{staticClass:"auth-btn gmail-btn",on:{"click":function($event){return _vm.authenticate('gmail')}}},[_vm._v(" Authenticate with Gmail ")]):(_vm.companyMailConfig.service === 'outlook')?_c('button',{staticClass:"auth-btn outlook-btn",on:{"click":function($event){return _vm.authenticate('outlook')}}},[_vm._v(" Authenticate with Outlook ")]):(_vm.companyMailConfig.service === 'gSuite')?_c('button',{staticClass:"auth-btn gsuite-btn",on:{"click":function($event){return _vm.authenticate('gSuite')}}},[_vm._v(" Authenticate with G Suite ")]):_vm._e()])]:(
      !Object.keys(_vm.companyMailConfig)?.length &&
      !Object.keys(_vm.loginUserMailConfig)?.length
    )?[_c('h2',[_vm._v("Access Denied")]),_c('p',[_vm._v("Please Activate your Email Client then you get the access")])]:(Object.keys(_vm.loginUserMailConfig).length && _vm.showTextArea)?[(_vm.companyMailConfig.isInboxVisible)?[_c('div',{staticClass:"mail-head"},[_c('table',{staticStyle:{"width":"100%"}},[_c('tr',[_c('th',{staticStyle:{"width":"80%"}},[_vm._v("   "+_vm._s(_vm.companyMailConfig?.service?.toUpperCase())+" "),_c('small',[_vm._v(" ( "+_vm._s(_vm.companyMailConfig?.connectionType?.toUpperCase())+") ")])]),_c('td',{staticStyle:{"width":"15%","text-align":"center"}},[_c('label',[_vm._v("Status")]),_vm._v("     "),_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949"},on:{"change":_vm.updateStatus},model:{value:(_vm.companyMailConfig.isActive),callback:function ($$v) {_vm.$set(_vm.companyMailConfig, "isActive", $$v)},expression:"companyMailConfig.isActive"}})],1),_c('td',{staticStyle:{"width":"5%","text-align":"center"}},[_c('el-button',{attrs:{"type":"danger","size":"mini","icon":"el-icon-delete","circle":""},on:{"click":function($event){return _vm.removeIntegration(_vm.loginUserMailConfig._id)}}})],1)])])]),_c('div',{staticClass:"mail-box"},[_c('app-sidebar',{attrs:{"messages":_vm.messages,"mailConfiguration":{
          ..._vm.companyMailConfig,
          user: _vm.loginUserMailConfig,
        }}}),_c('app-content',{attrs:{"messages":_vm.messages,"mailConfiguration":{
          ..._vm.companyMailConfig,
          user: _vm.loginUserMailConfig,
        }}})],1)]:[_c('el-row',[_c('el-col',{staticClass:"mt-1 mb-5 pt-1 pb-5 pl-1 pr-1",attrs:{"xs":24,"sm":24,"md":24,"lg":24,"xl":24}},[_c('div',{staticStyle:{"padding":"0 0.55rem"}},[_c('table',{attrs:{"id":"customers"}},[_c('tr',[_c('th',[_vm._v("Service")]),_c('td',[_vm._v(_vm._s(_vm.companyMailConfig?.service?.toUpperCase()))])]),(_vm.companyMailConfig.connectionType === 'oauth')?_c('tr',[_c('th',[_vm._v("Connection Type")]),_c('td',[_vm._v(_vm._s(_vm.companyMailConfig?.connectionType?.toUpperCase()))])]):_vm._e(),(_vm.companyMailConfig.connectionType === 'oauth')?_c('tr',[_c('th',[_vm._v("Domain")]),_c('td',[_vm._v(_vm._s(_vm.companyMailConfig.domain || "N/A"))])]):_vm._e(),(_vm.companyMailConfig.connectionType === 'imap')?_c('tr',[_c('th',[_vm._v("Host")]),_c('td',[_vm._v(_vm._s(_vm.companyMailConfig.imapConfig?.host || "N/A"))])]):_vm._e(),(_vm.companyMailConfig.connectionType === 'imap')?_c('tr',[_c('th',[_vm._v("Username")]),_c('td',[_vm._v(_vm._s(_vm.companyMailConfig.imapConfig?.username || "N/A"))])]):_vm._e(),(_vm.companyMailConfig.connectionType === 'imap')?_c('tr',[_c('th',[_vm._v("Port")]),_c('td',[_vm._v(_vm._s(_vm.companyMailConfig.imapConfig?.port || "N/A"))])]):_vm._e(),_c('tr',[_c('th',[_vm._v("Status")]),_c('td',[_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949"},on:{"change":_vm.updateStatus},model:{value:(_vm.companyMailConfig.isActive),callback:function ($$v) {_vm.$set(_vm.companyMailConfig, "isActive", $$v)},expression:"companyMailConfig.isActive"}})],1)]),_c('tr',[_c('th',[_vm._v("Actions")]),_c('td',[_c('el-button',{attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.removeIntegration(_vm.loginUserMailConfig._id)}}},[_vm._v(" Remove ")])],1)])])])])],1)]]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }