<template>
  <div class="inbox-body">
    <!-- Pass fetched inbox data to the child component -->
    <app-messages :messages="inbox"></app-messages>
  </div>
</template>

<script>
// import { bus } from "../../../main";
import Messages from "./messages.vue";
import { fetchInboxMessages } from "./data/messages";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    mailConfiguration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inbox: [], // Store fetched messages here
    };
  },
  created() {
    this.getMessages();
  },
  // activated() {
  //   // Refresh component data when it is activated
  //   this.getMessages();
  // },
  methods: {
    async getMessages() {
      try {
        // Fetch messages and assign them to `inbox`
        let data = await fetchInboxMessages(
          this.mailConfiguration.service,
          this.mailConfiguration.user._id
        );
        // this.inbox = [...data];
        this.$set(this, "inbox", data);
      } catch (error) {
        console.error("Failed to fetch messages:", error);
      }
    },
  },
  computed: {
    incomingMessages() {
      // Keep this in case other logic uses it
      return this.data.messages.filter(function (message) {
        return message.type === "incoming" && !message.isDeleted;
      });
    },
  },
  components: {
    appMessages: Messages,
  },
};
</script>
