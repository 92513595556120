<template>
  <div>
    <!-- Mail Options Moved Outside inbox-body -->
    <div class="mail-option">
      <button class="btn btn-primary" @click="navigateBack" title="Back">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
      </button>

      <button
        class="btn btn-danger"
        @click="data.message.isDeleted = true"
        :disabled="data.message.isDeleted"
        title="Delete"
      >
        <i class="fa fa-trash-o"></i>
      </button>

      <template v-if="typeof data.message.isRead !== 'undefined'">
        <button
          class="btn btn-primary"
          @click="data.message.isRead = false"
          :disabled="!data.message.isRead"
          title="Mark as unread"
        >
          <i class="fa fa-envelope-open" aria-hidden="true"></i>
        </button>

        <button
          class="btn btn-primary"
          @click="data.message.isRead = true"
          :disabled="data.message.isRead"
          title="Mark as read"
        >
          <i class="fa fa-envelope" aria-hidden="true"></i>
        </button>
      </template>
    </div>

    <!-- Inbox Body -->
    <div class="inbox-body">
      <div class="email-details">
        <div class="sender-circle" :title="data.message.from.email">
          {{ data.message.from.name.charAt(0).toUpperCase() }}
        </div>
        <div class="email-info">
          <p class="subject">
            {{ data.message.subject }}
          </p>
          <p class="time">
            {{ getDateValue(data.message.date) }}
          </p>
        </div>
      </div>

      <hr />

      <div v-html="data.message.content" class="message"></div>

      <div v-if="data.message.attachments.length > 0" class="attachments">
        <h4>Attachments</h4>

        <ul>
          <li
            v-for="(attachment, index) in data.message.attachments"
            :key="index"
          >
            <i class="fa fa-paperclip"></i> {{ attachment.fileName }} ({{
              attachment.size | formatBytes
            }})
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../../main";
import moment from "moment";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  activated() {
    if (typeof this.data.message.isRead !== "undefined") {
      this.data.message.isRead = true;
    }
  },
  computed: {
    getDateValue() {
      return (date) => {
        return moment(date).fromNow();
      };
    },
  },
  methods: {
    navigateBack() {
      let previousView = this.$parent.previousView;

      bus.$emit("changeView", {
        tag: previousView.tag,
        title: previousView.title,
        data: previousView.data,
      });
    },
  },
  filters: {
    formatBytes(bytes) {
      if (bytes == 0) {
        return "0 Bytes";
      }

      let decimals = 2;
      let k = 1000;
      let dm = decimals + 1 || 3;
      let sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
  },
};
</script>

<style scoped>
/* Inbox Body */
.inbox-body {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-width: thin;
  scrollbar-color: #e2e5e9 #f9f9f9;
}

/* Mail Options Styling */
.mail-option {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Email Details Styling */
.email-details {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.sender-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}

.email-info {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subject {
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
}

.time {
  font-size: 14px;
  color: gray;
  text-align: right;
}

/* Message Content */
.message {
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 20px;
  white-space: normal;
  word-wrap: break-word;
}

/* Attachments */
.attachments {
  margin-top: 20px;
}

.attachments h4 {
  font-size: 16px;
  margin-bottom: 10px;
}

.attachments ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.attachments li {
  font-size: 14px;
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.attachments li i {
  margin-right: 8px;
  color: #6c757d;
}

/* Responsive Design */
@media (max-width: 768px) {
  .mail-option {
    flex-direction: column;
    gap: 10px;
  }

  .message {
    font-size: 14px;
  }

  .attachments ul {
    font-size: 14px;
  }

  .subject {
    max-width: 50%;
  }
}
</style>
